<script setup lang="ts">
/*
 * File: dashboard.vue
 * Description: Base layout for all pages in /dashboard
 */

import {useDisplay} from "vuetify";
import type {TAccount} from "~/utils/ecrf_extracted";
import {useAccountStore} from "~/utils/stores/accountStore";
import {usePatientStore} from "~/utils/stores/patientStore";

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - eCRF` : 'eCRF Dashboard';
  }
});

const { mobile } = useDisplay();
const drawerState = ref(!mobile.value);

// Interface for routes
interface Route {
  route?: string;
  isWildcard?: boolean;
  routes?: Route[];
}

// define routes with urls for navigation
// TODO: Wrap urls in i18n helper function call (see nuxt-i18n documentation)
const routes = {
  dashboard: {
    route: "/dashboard",
  },
  users: {
    route: "/dashboard/users",
    routes: [
      {
        route: "/dashboard/users/new",
      },
      {
        route: "/dashboard/users/edit",
        isWildcard: true,
      }
    ]
  },
  studies: {
    patients: {
      route: "/dashboard/studies/patients",
      routes: [
        {
          route: "/dashboard/studies/patients/new",
        }
      ]
    },
    visits: {
      route: "/dashboard/studies/visits",
      routes: [
        {
          route: "/dashboard/studies/visits/new",
        },
        {
          route: "/dashboard/studies/visits/edit",
          isWildcard: true,
        },
        {
          route: "/dashboard/studies/visits/history",
          isWildcard: true,
        }
      ]
    }
  },
  locations: {
    route: "/dashboard/locations",
    routes: [
      {
        route: "/dashboard/locations/new",
      },
      {
        route: "/dashboard/locations/edit",
        isWildcard: true,
      }
    ]
  },
};

const route = useRoute();
// Determine if the navigation item is active based on the current route
const routeActive = (baseRoute: Route): boolean => {
  if (baseRoute.routes) {
    for (const route of baseRoute.routes) {
      const isActive = routeActive(route);
      if (isActive) {
        return isActive;
      }
    }
  }
  return !!(
    baseRoute.route
    &&
    (
      route.path === baseRoute.route
      ||
      (
        baseRoute.isWildcard
        &&
        route.path.startsWith(baseRoute.route)
      )
    )
  );
}

const accountStore = useAccountStore();

if (!accountStore.account.id) {
  accountStore.fetchAccountAsync();
}

const runtimeConfig = useRuntimeConfig();
const alwaysOpen = ref(["studies"]);

const supabase = useSupabaseClient();
const onSignout = async () => {
  // clear the cached user data
  accountStore.$reset();

  // clear the cached last patient data
  const patientStore = usePatientStore();
  patientStore.$reset();

  // logout user
  await supabase.auth.signOut();
  navigateTo("/");
}
</script>

<template>
  <v-navigation-drawer v-model="drawerState">
    <v-list open-strategy="multiple" v-model:opened="alwaysOpen">
      <v-list-item title="eCRF" :subtitle="`Angemeldet als ${accountStore.account.name}`"></v-list-item>
      <v-divider></v-divider>
      <!-- TODO: Rewrite this into a component that takes 'routes' as input -->
      <v-list-item
        link
        :to="routes.dashboard.route"
        :active="routeActive(routes.dashboard)"
        title="Übersicht"
        prepend-icon="mdi-view-dashboard"
      ></v-list-item>
      <v-list-item
        link
        v-if="accountStore.account.is_super_admin"
        :to="routes.users.route"
        :active="routeActive(routes.users)"
        title="Benutzer"
        prepend-icon="mdi-account-group"
      ></v-list-item>
      <v-list-group value="studies">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-school"
            title="Studien"
          ></v-list-item>
        </template>

        <v-list-item
          link
          :to="routes.studies.patients.route"
          :active="routeActive(routes.studies.patients)"
          title="Patienten"
          prepend-icon="mdi-account"
        ></v-list-item>
        <v-list-item
          link
          :to="routes.studies.visits.route"
          :active="routeActive(routes.studies.visits)"
          title="Visiten"
          prepend-icon="mdi-card-account-details-outline"
        ></v-list-item>
      </v-list-group>
      <v-list-item
        link
        v-if="accountStore.account.is_super_admin"
        :to="routes.locations.route"
        :active="routeActive(routes.locations)"
        title="Standorte"
        prepend-icon="mdi-office-building-marker-outline"
      ></v-list-item>
    </v-list>
    <template v-slot:append>
      <div class="d-flex ma-1 mb-0">
        <v-btn class="w-100" variant="text" @click="onSignout">
          Abmelden
        </v-btn>
      </div>
      <div class="pa-1">
        <p class="text-sm-caption">v{{ runtimeConfig.public.clientVersion }} ({{ runtimeConfig.public.clientVersionDate }})</p>
        <p class="text-sm-caption">Copyright 2024 <a href="https://dekoster-it.com" target="_blank">dekoster IT & Consulting</a></p>
      </div>
    </template>
  </v-navigation-drawer>

  <v-app-bar>
    <v-app-bar-nav-icon @click="drawerState = !drawerState"></v-app-bar-nav-icon>

    <v-app-bar-title>eCRF MOCKUP</v-app-bar-title>
  </v-app-bar>

  <v-main class="ma-1">
    <slot />
  </v-main>
</template>

<style scoped>

</style>
